<template>
  <v-app v-cloak>
    <notifications group="notifications" :position="'top right'"></notifications>
    <core-view></core-view>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    showBanner: true, // Controls visibility of the banner
  }),
};
</script>

<style>
/* Styles for the fixed banner */
.fixed-banner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}
</style>
